import { capitalizeFirstLetter } from '@/utils/helpers';

export default {
  platform: {
    careerpass: {
      name: 'CareerPass',
      schoolDisabledTitle: 'This Website has been disabled by @:(platform.careerpass.name).',
      schoolDisabledSubTitle: 'Please contact support if you have any questions.',
    },
    careertools: {
      name: 'Career Tools',
      schoolDisabledTitle:
        'This Website has been disabled by @:(platform.careertools.name), either at the request of the school or, an account has not been paid.',
      schoolDisabledSubTitle:
        "Please contact @:(platform.careertools.name) via <a href='https://www.careertools.com.au/contact'>www.careertools.com.au/contact</a> if you have any questions.",
    },
  },
  yearGroupShort: 'Year',
  yearGroupShortPlural: 'Years',
  yearGroupLong: 'Year Group',
  toCapital: ({ named }) =>
    typeof named('text') === 'string' ? capitalizeFirstLetter(named('text')) : '',
  general: {
    classYear: 'year', // As in "Year 13"
    customise: 'customise',
    personalise: {
      label: 'personalise',
      pastTenseLabel: 'personalised',
    },
    favourite: {
      label: 'favourite | favourites',
      pastTenseLabel: 'favourited',
      verb: 'favouriting',
    },
    job: {
      title: 'job | jobs',
      titleWithPrefix: 'a job',
    },
    searchSubText: 'Search students by name or keyword',
  },
  careerPass: {
    title: 'Wallet',
  },
  psp: {
    title: 'Career Copilot',
    short_title: 'Copilot',
    tableHeaderLabel: 'Active Career Action Plans',
    actionPlanSteps: {
      ACTION_STEP_1: {
        title: 'Explore & Favourite the jobs you like',
        description:
          'Browse careers on the explore careers page and click the ‘Favourite’ button for the jobs you find interesting.',
      },
      ACTION_STEP_2: {
        description:
          'If you have favourites, start an action plan for a job you love; if not, no worries – you can still begin a plan for any career that catches your eye.',
      },
    },
    quizQuestionsOptions: {
      'Organised and structured': 'Organised and structured',
    },
    recommendation_title: 'Activate @:psp.title',
  },
  academy: {
    title: 'Academy',
    learn_life_skills: 'Learn Life Skills',
    explore_careers: 'Industry Tasters',
    virtual_work_experiences: 'Virtual Work Experiences',
  },
  skillTooltip: {
    'Active Learning': 'Applying new information to both current and future problems and tasks',
    'Active Listening':
      'Paying full attention when someone is speaking, asking questions, and not interrupting',
    'Complex Problem Solving':
      'Figuring out how to fix difficult problems by looking at all the details and coming up with a plan',
    Coordination: 'Working smoothly with others by adjusting what you do based on their actions',
    'Critical Thinking':
      'Using logic to figure out the strengths and weaknesses of different solutions',
    'Equipment Maintenance': 'Keeping machines working well through regular care',
    'Equipment Selection': 'Determining the kind of tools and equipment needed to do a job',
    Installation: 'Installing equipment, machines, wiring, or programs to meet specifications',
    Instructing: 'Teaching or guiding others on how to do something',
    'Judgment and Decision Making':
      'Thinking carefully about the possible outcomes before making a choice',
    'Learning Strategies:': 'Finding and using the best ways to learn or teach something new',
    'Management of Financial Resources': 'Using money wisely in different projects',
    'Management of Personnel Resources':
      'Motivating, developing, and directing people as they work, identifying the best people for the job',
    'Material Resource Management': 'Getting and managing the tools and equipment needed for a job',
    Mathematics: 'Using maths to figure out and solve problems',
    Monitoring: 'Keeping an eye on how well things are going and making changes if needed',
    Negotiation: 'Trying to find a middle ground in disagreements',
    'Operation and Control': 'Running machines or systems smoothly',
    'Operations Analysis': 'Examining requirements and needs to design solutions.',
    'Operations Monitoring': "Watching and checking equipment to ensure it's working right",
    Persuasion: 'Convincing others to see things your way or change their behaviour',
    Programming: 'Writing code to create software or applications',
    'Quality Control Analysis': 'Making sure systems and products meet quality standards',
    'Reading Comprehension':
      'Understanding and interpreting what you read in books, emails, and documents',
    Repairing: 'Fixing machines or systems using the right tools',
    Science: 'Applying scientific methods to solve problems',
    'Service Orientation:': 'Eagerly looking for ways to help or serve others',
    'Social Perceptiveness': "Noticing and understanding other people's reactions and feelings",
    Speaking: 'Talking clearly and effectively to share information with others',
    'Systems Analysis': 'Studying how systems work and how changes can affect them',
    'Systems Evaluation:': 'Judging how well a system is working and how to improve it',
    'Technology Design': "Creating or adapting equipment and technology to meet people's needs",
    'Time Management': "Organising your time and others' to get things done",
    Troubleshooting: "Figuring out what's wrong when there's a problem and how to fix it",
    Writing: 'Expressing ideas clearly in writing, tailored for who will be reading it',
    'Technology Awareness':
      'Staying up-to-date with the latest technology and applying it to your work',
    Teamwork: 'Working well with others',
  },
};
